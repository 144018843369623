<template>
  <keep-alive :include="['closeoutIndex']">
    <router-view></router-view>
  </keep-alive>
</template>

<script>
export default {
  data(){
    return{
      cacheList: ['closeoutIndex']
    }
  },
}
</script>

<style>

</style>

